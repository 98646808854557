import qbitImg from '../images/qbit.png';

export const supportNetwork = { 
    // 3 : {
    //     name : "ROPSTEN",
    //     chainId : 3,
    //     rpc : "https://ropsten.infura.io/v3/63f4b8ee61284419b46c780d03befc4e",
    //     image : "etherum.png"
    // },
    // 56 : {
    //     name : "BINANCE",
    //     chainId : 56,
    //     rpc : "https://bsc-dataseed.binance.org/",
    //     image : "binance-coin.svg"
    // },
    13600 : {
        name : "KNB",
        chainId : 13600,
        rpc : "https://mainnet-rpc.qbitscan.com/",
        image : qbitImg
    },
    'default':{
        name : "KNB",
        chainId : 13600,
        rpc : "https://mainnet-rpc.qbitscan.com/",
        image : qbitImg
    }

}

export const RPC_URLS = {
    // 7622 : "https://testnet-rpc.qbitscan.com/",
    13600 : "https://mainnet-rpc.qbitscan.com/",
    // 56: "https://bsc-dataseed.binance.org/",
    // 97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    // 3: "https://ropsten.infura.io/v3/63f4b8ee61284419b46c780d03befc4e",
    // 137 : "https://polygon-rpc.com/",
    // 1 : "https://mainnet.infura.io/v3/63f4b8ee61284419b46c780d03befc4e"
  };

