export const contract = { 
    13600 : {
        stakingAddress : "0x762860BbBc43eb1C611076f18668d7ce6b23D4E6",
        multicallAddress : "0xAd08c500E7b180E4f762E2aAeB2d4D9803a2273e"
    },
    'default':{
        stakingAddress : "0x762860BbBc43eb1C611076f18668d7ce6b23D4E6",
        multicallAddress : "0xAd08c500E7b180E4f762E2aAeB2d4D9803a2273e"
    }

}

