import React from "react";
import logoWhite from '../images/logo-black.png';

export default function Footer() {

    return (
        <React.Fragment>
            <footer class="template-footer">
                <div class="container">
                    <div class="row">
                        <div class="col-12 text-center">
                            <img class="logo-footer" src={logoWhite} alt="Imageq" />
                            <p class="w-70 m-t-20">Kronobit Networks is the world’s first Educative, Secured Decentralized EVM compatible with high performance and scalable</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-center">
                            <ul class="icon-group justify-content-center social-links">
                                <li class="li-sm-icons">
                                    <a href="https://t.me/kronobit" rel="noreferrer" target="_blank"><i class="fab fa-telegram"></i></a>
                                </li>
                                <li class="li-sm-icons">
                                    <a href="https://twitter.com/Kronobit_Knb" rel="noreferrer" target="_blank"><i class="fab fa-twitter"></i></a>
                                </li>
                                <li class="li-sm-icons">
                                    <a href="https://kronobitnetworks.medium.com/" rel="noreferrer" target="_blank"><i class="fab fa-medium"></i></a>
                                </li>
                                <li class="li-sm-icons">
                                    <a href="https://www.instagram.com/kronobit/" rel="noreferrer" target="_blank"><i class="fab fa-instagram"></i></a>
                                </li>
                                <li class="li-sm-icons">
                                    <a href="https://www.youtube.com/@kronobit_networks" rel="noreferrer" target="_blank"><i class="fab fa-youtube"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>



        </React.Fragment>
    )
}
